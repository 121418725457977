/* === Timeline === */
.timeline {
    position: relative;
}

.timeline .timeline-container {
    padding-left: 50px;
    margin-bottom: 50px;
    position: relative;
    background-color: inherit;
}

.timeline.edu .timeline-container::after {
    content: '\e019';
    font-family: 'simple-line-icons';
    font-size: 24px;
    color: #E250E5;
    position: absolute;
    left: 0px;
    top: 0;
    z-index: 2;
}

.timeline.check .timeline-container::after {
    content: '\e080';
    font-family: 'simple-line-icons';
    font-size: 24px;
    background: -moz-linear-gradient(top, #e72c83 0%, #a742c6 100%);
    background: -webkit-linear-gradient(top, #e72c83 0%, #a742c6 100%);
    background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    left: 0px;
    top: 0;
    z-index: 2;
}

.timeline .content {
    position: relative;
    left:5px;
}

.timeline .content .time {
    color: #8B88B1;
    font-size: 14px;
}

.timeline .content h3 {
    font-size: 20px;
    margin: 10px 0;
}

.timeline .content p {
    margin: 0;
}

.timeline span.line {
    position: absolute;
    width: 1px;
    background-color: #E250E5;
    top: 30px;
    bottom: 30px;
    left: 36px;
}