@font-face {
  font-family: 'fa-brands';
  src:  url('./font/fa-brands-400.eot');
  src:  url('./font/fa-brands-400.eot') format('embedded-opentype'),
    url('./font/fa-brands-400.ttf') format('truetype'),
    url('./font/fa-brands-400.woff') format('woff'),
    url('./font/fa-brands-400.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="brands-"], [class*=" brands-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'brands' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brands-youtube:before {
  content: "\f167";
  font-family: 'fa-brands';
}
.brands-github:before {
  content: "\f09b";
  font-family: 'fa-brands';
}
.brands-discord:before {
  content: "\f392";
  font-family: 'fa-brands';
}
.brands-twitter:before {
  content: "\f099";
  font-family: 'fa-brands';
}
.brands-shoppify:before {
  content: "\f957";
  font-family: 'fa-brands';
}


